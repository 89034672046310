import React, { useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
//import socket from './src/utils/socket';
import 'react-toastify/dist/ReactToastify.css';
import useAuthState from './src/stores/auth';

const initializeOneSignal = (user) => {
  if (typeof window !== 'undefined') {
    const script = document.createElement('script');
    script.src = 'https://cdn.onesignal.com/sdks/OneSignalSDK.js';
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      window.OneSignal = window.OneSignal || [];
      window.OneSignal.push(function() {
        window.OneSignal.init({
          appId: '36da0faf-03ee-4ae0-89ce-e5156619cf9f',
          notifyButton: {
            enable: true,
          },
          welcomeNotification: {
            title: 'Welcome!',
            message: 'Thanks for subscribing to notifications.',
          },
        });

        // Check if push notifications are enabled
        window.OneSignal.isPushNotificationsEnabled((isEnabled) => {
          if (!isEnabled) {
            // User is not subscribed, prompt to subscribe
            window.OneSignal.showSlidedownPrompt();
          }
        });


        if (user && user._id) {
          const externalUserId = `user_${user._id}_${Date.now()}`;
          
          window.OneSignal.setExternalUserId(externalUserId);

          fetch(`${process.env.GATSBY_API_URL}/users/saveExternalUserId`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ userId: user._id, externalUserId }),
          })
          .then(response => response.json())
          .then(data => {
            if (data.message === 'External User ID saved successfully.') {
              console.log('External User ID saved successfully');
            } else {
              console.error('Failed to save External User ID');
            }
          })
          .catch(error => {
            console.error('Error saving External User ID:', error);
          });
        }
      });
    };

    script.onerror = () => {
      console.error('Error loading OneSignal SDK');
    };
  }
};

const RootElementWrapper = ({ element }) => {
  const { user, isLoggedIn } = useAuthState();

  useEffect(() => {
    initializeOneSignal(user);

    // if (isLoggedIn()) {
    //   socket.emit('userConnect', user._id);

    //   socket.on('connectionSuccess', () => {
    //     //toast.success('Connected');
    //   });

    //   socket.on('orderCancel', productName => {
    //     toast.success(`Your order of ${productName} has been cancelled.`);
    //   });

    //   socket.on('orderReceived', productName => {
    //     productName
    //       ? toast.success(`Your order of ${productName} has been received.`)
    //       : toast.success('Your order has been received.');
    //   });

    //   socket.on('orderConfirmed', productName => {
    //     toast.success(`Your order of ${productName} has been confirmed.`);
    //   });

    //   socket.on('orderQuoted', productName => {
    //     toast.success(`Your order of ${productName} has been quoted.`);
    //   });

    //   socket.on('prescriptionReceived', () => {
    //     toast.success('Your prescription has been received.');
    //   });
    // }

    // return () => {
    //   socket.disconnect();
    // };
  }, [isLoggedIn, user]);

  return element;
};

export const wrapRootElement = ({ element }) => (
  <>
    <ToastContainer />
    <RootElementWrapper element={element} />
  </>
);
